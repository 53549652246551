import MarkDownViewer from '@ui/MarkdownViewer';
import { memo } from 'react';
import { block } from 'bem-cn';
import StaticIcon from '../../../StaticIcon';
import Typography from '../../../Typography';
import '../style.scss';

const cn = block('price_support_block');

// ---------------------------------------------------------------

const Question = ({ question, handleClick, id }) => {
  const open = id === question.id;
  return (
    <li className="border-b border-tertiary300 py-3 last-of-type:border-0">
      <div onClick={handleClick} className="flex cursor-pointer justify-between gap-4">
        <Typography
          color="secondary400"
          className="my-0 text-xl font-medium md:font-semibold lg:font-semibold"
          as="h3"
        >
          {question.frontmatter.title}
        </Typography>
        <StaticIcon name="chevron-down" className={open && 'rotate-180'} />
      </div>
      {open && (
        <MarkDownViewer className="price_support_block__paragraph">
          {question.htmlAst}
        </MarkDownViewer>
      )}
    </li>
  );
};

export default memo(Question);
