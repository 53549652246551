import { TARIFFS } from '@assets/content/constants';
import { RegisterPageButton } from '@features/registration';
import { RequestFeedback } from '@features/request-feedback';
import { t } from '@lib/i18n';
// ui
// styles
import { block } from 'bem-cn';
import clsx from 'clsx';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, {
  memo, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
// context
import { TariffContext } from '../../../../routes/price/ui/PriceContext';
import { paths } from '../../../paths';
import Button from '../../Button';

import Typography from '../../Typography';
import {
  AdditionalModule, ButtonsBlock, CheckList, IncludeOption, Price,
} from './components';
import './style.scss';

const cn = block('price_block');

// -----------------------------------------------------------------

export const Caption = ({
  text,
  className,
  center,
  variant = 'body2',
}) => (
  <Typography
    variant={variant}
    color="secondary100"
    className={cn('caption')
      .mix(className)}
    align={center ? 'center' : ''}
  >
    {text}
  </Typography>
);

// ------------------------------------------------------------------

export const TryButton = ({ 
  id, quickStart, text, className, blank = false,
}) => {
  const handleClick = useCallback(() => {
    if (blank) {
      window.open(`${window.location.origin}${paths.registration.path}`, '_blank');
    } else {
      navigate(paths.registration.path);
    }
  }, [blank]);
  return (
    <Button
      text={text || (quickStart ? t('tariffs.registration') : 'Попробовать')}
      onClick={handleClick}
      id={id}
      outline={!quickStart}
      className={className}
    />
  );
};

const PriceBlock = ({ tariff }) => {
  return (
    <section className={cn('wrap')}>
      <div className={cn('container').mix(clsx('px-4 pt-5 pb-6 sm:mx-auto sm:max-w-[400px] sm:bg-white'))}>
        <Typography as="h3" variant="header5" className={cn('title')}>
          {tariff.name}
        </Typography>
        <div className="mb-3">
          <Typography className={cn('description').mix(clsx('!text-secondary100 text-xs'))}>
            {tariff.description}
          </Typography>
          <Typography className="font-medium text-xs">
            {tariff.persons}
          </Typography>
        </div>
        {tariff.quickStart ? (
          <div className="mb-3 rounded-full bg-tertiary200 px-2 py-[6px] text-center text-xs text-secondary400">
            {tariff.price}
          </div>
        )
          : (
            <Price
              value={tariff.price}
              period={tariff.period}
              currency={tariff.currency}
              request={tariff.request_price}
            />
          )}
        <div className={cn('button-container')}>
          {tariff.tryTariff ? (
            <TryButton id={tariff.name} blank />
          ) : tariff.quickStart ? (
            <TryButton id={tariff.name} quickStart blank />
          ) : (
            <RequestFeedback.Price.Button outline>
              Запросить расчёт
            </RequestFeedback.Price.Button>
          )}
        </div>
        <div>
          <CheckList list={tariff.check_list_id} />
        </div>
      </div>
    </section>
  );
};

PriceBlock.propTypes = {
  tariff: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currency: PropTypes.string,
    period: PropTypes.string,
    description: PropTypes.string,
    persons: PropTypes.string,
    check_list_id: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    tryTariff: PropTypes.bool,
    quickStart: PropTypes.bool,
  }),
};

export default memo(PriceBlock);
