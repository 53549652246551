import {
  PostActions,
  PostIntro, RecommendedPosts, useSitePosts,
} from '@entities/post';
import { FeatureLayout } from '@widgets/layout';
import { NotFoundPage } from '@routes/not-found';
// ui
import MediaQuery from '@ui/MediaQuery';
import MarkdownViewer from '@ui/MarkdownViewer'; 
// styles
import { block } from 'bem-cn';
import { graphql } from 'gatsby';
import {
  memo, useEffect, useRef,
  useState,
} from 'react';

import { usePostVisits } from '@hooks/usePostVisit';
import { useLocation } from '@reach/router';

import './style.scss';
import { Sidebar } from '@widgets/side-bar';
import { TelegramButton } from '@ui/TelegramButton';
import { CtaCard } from '@ui/CtaCard';

const cn = block('post-page');

const FeatureContent = ({ frontmatter, duration }) => (
  <div className="grid-layout mx-auto">
    <PostIntro post={{ ...frontmatter, duration }} />
  </div>
);

// ----------------------------------------------------------------------------

const Template = ({ data }) => {
  const { hash } = useLocation();
  const postView = usePostVisits();
  let { markdownRemark } = data;
  if (markdownRemark === null || markdownRemark === undefined) {
    markdownRemark = { frontmatter: {}, htmlAst: {}, timeToRead: 0 };
  }
  const { frontmatter, htmlAst, timeToRead } = markdownRemark;
  const duration = timeToRead;
  const {
    slug,
    coverImageUrl,
    SeoDescription,
    SeoTitle,
    category,
    tags,
  } = frontmatter;
  const [menu, setMenu] = useState();

  const postContentRef = useRef();
  
  useEffect(() => {
    htmlAst.children.every((value) => {
      if (value.tagName === 'sidemenu') {
        const dataString = value && value.children && value.children[0].value;
        try {
          setMenu(JSON.parse(`[${dataString}]`));
        } catch (err) {
          console.log(`Parsing error. Data: ${dataString} Error: ${err}`);
        }
        return false;
      }
      return true;
    });
  }, [htmlAst]);

  useEffect(() => {
    const postHeaders = postContentRef.current ? Array.from(postContentRef.current.querySelectorAll('h2')) : [];

    if (menu && postHeaders.length && menu.length) {
      postHeaders.forEach((headerNode, i) => {
        headerNode.setAttribute('id', headerNode.innerText ? menu[i].link : '');
      });
    }
  }, [menu]);

  useEffect(() => {
    if (hash && postContentRef.current) {
      const tag = postContentRef.current.querySelector(hash);
      if (tag) {
        tag.scrollIntoView();
      }
    }
  }); // don`t use dependencies array for useEffect. tag is null by reload if use array

  const recommendedPosts = useSitePosts().all.filter((post) => [category, ...tags || []].some((i) => [post.category, ...post.tags].includes(i)) && post.id !== frontmatter.id).slice(0, 6);

  if (slug === undefined) {
    return NotFoundPage;
  }
  return (
    <FeatureLayout
      mainLayoutProps={
        { 
          seo: {
            title: SeoTitle,
            description: SeoDescription,
            link: slug,
            type: 'article',
            image: coverImageUrl,
          }, 
          banner: true, 
        } 
      }
      featureChildren={<FeatureContent frontmatter={frontmatter} duration={duration} />}
      className="!py-0"
    >
      <article className="grid-layout mx-auto sm:px-3 lg:flex">
        <div className="lg:col-12">
          <div className={cn('post', { container: true })}>
            <MediaQuery greaterThan="md">
              <Sidebar className="w-[112px]">
                <PostActions vertical title={SeoTitle} />
              </Sidebar>
            </MediaQuery>
            <div
              className={cn('post', { content: true })
                .mix(
                  cn('content', { wrap: true }),
                  'lg:col-8',
                )}
              ref={postContentRef}
            >
              <MarkdownViewer>
                {htmlAst}
              </MarkdownViewer>
            </div>
            <MediaQuery greaterThan="md">
              <div className="ml-4 flex flex-col gap-4">
                <CtaCard sidebar />
                <Sidebar className="w-full">
                  <TelegramButton outline icon className="w-full" />
                </Sidebar>
              </div>
            </MediaQuery>
          </div>
        </div>
      </article>
      <MediaQuery lessThan="lg">
        <div className="grid-layout mx-auto my-8 ">
          <PostActions title={frontmatter.title} />
        </div>
      </MediaQuery>
      {recommendedPosts && (
        <RecommendedPosts
          className="mt-4 sm:mt-8"
          posts={recommendedPosts}
        />
      )}
    </FeatureLayout>
  );
};

export default memo(Template);

export const query = graphql`
query($id: String!) {
  markdownRemark(
    id: {eq: $id},
    frontmatter: { type: {eq: "post"}}
    ) {
    htmlAst
    timeToRead
      frontmatter {
          id
          author
          category
          tags
          coverImageUrl
          coverImageAlt
          createdAt
          slug
          title
          avatar
          sourceHref
          sidemenu {
            name
            link
          }
          SeoTitle
          SeoDescription
          downloadButton {
            text
            modal
            docname  
          }
      }
    }
  }
`;
